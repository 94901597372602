import https from "./https";
import store from "@/store";

const post = {
  getPosts(merchantId, params) {
    const organization = store.state.general.organization;
    const searchParams = new URLSearchParams(params);

    return https.get(
      `/admin/organizations/${organization.id}/merchants/${merchantId}/posts?${searchParams}`
    );
  },

  getPost(postId) {
    return https.get(`/admin/posts/${postId}`);
  },

  createPost(merchantId, post) {
    const organization = store.state.general.organization;

    return https.post(
      `/admin/organizations/${organization.id}/merchants/${merchantId}/posts`,
      post
    );
  },

  updatePost(postId, post) {
    return https.put(`/admin/posts/${postId}`, post);
  },

  deletePost(postId) {
    return https.delete(`/admin/posts/${postId}`);
  },
};

export default post;
